* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}


/* TODO this is the size and spacing for mobile nav */
.App-logo {
  height: 20vmin;
  margin-right: 70%;
  margin-top: 1rem;
  margin-bottom: 9rem;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #0e1530;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */

a {
  box-shadow: none;
  color: inherit;
  text-decoration: none;
}